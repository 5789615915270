export default {
    page: {
        title: "Calculate your usage of concrete here",
        teaser: "Here you can calculate your conrete usage for different tasks. Select and fill in below."
    },
    length: "Length",
    width: "Width",
    height: "Height",
	blockType: "Block Type",
    depth: "Depth",
    radius: "Radius",
    thickness: "Thickness",
    result: "Result"
}
