export default {
    page: {
        title: "Beregn dit forbrug her",
        teaser: "Her kan du beregne dit cementforbrug ved forskellige opgaver. Vælg og udfyld her under.",
        getHelpText: "Få hjælp til at vælge",
        helpText: {
            title: "Hjælp til valg",
            getHelp: "Få hjælp til at vælge",
            closeHelp: "Luk hjælp"
        },
        closeHelpText: "Luk hjælp"
    },
    geometryType: {
        selectShape: "Vælg en form"
    },
    formData: {
        enterMeasurements: "Indtast dine mål",
        length: "Længde",
        width: "Bredde",
        height: "Højde",
		blockType: "Blok type",
        depth: "Dybde",
        radius: "Radius",
        thickness: "Tykkelse",
        thicknessPudslag: "Tykkelse af pudslag",
        thicknessSlidlag: "Tykkelse af slid-/afretningslag",
        useStoebemix: "Jeg vil benytte støbemix",
        calculateBtnText: "Udregn",
        restartBtnText: "Start forfra"
    },
    receipt: {
        title: "Forventet forbrug",
        choicesTitle: "Dine valg",
        geometryTitle: "Geometri",
        brickTitle: "Murstenstype",
        mixinTitle: "Blandingsforhold efter volumen",
        area: "Areal",
        cement: "Cement",
        kalkMortelWet: "Kalkmørtel, våd",
        stobemix: "Støbemix",
        water: "Vand",
        imprintTitle: "CO<sub>2</sub> aftryk ved denne opgave",
        volumeTitle: "Beregnet volumen",
        volumeDesc: "Størrelse af beregnet volumen",
        mortelVolumeDesc: "Totalvolumen, KC-Mørtel",
        slidlagVolumeDesc: "Totalvolumen, Cementmørtel",
        amountPerM3: "Mængder pr. m<sup>3</sup>",
        sand: "Sand",
        stone: "Sten",
        usageTotalTitle: "Forventet forbrug total",
        concretePropsTitle: "Betonegenskaber",
        mortelPropsTitle: "Mørtelegenskaber",
        expectedStrengthClass: "Forventet styrkeklasse [MPa]",
        exposureClasses: "Eksponeringsklasser",
        environmentalImpact: "Miljøpåvirkning",
        recommendedTitle: "Vi anbefaler",
        bagsTotalText: "Sække (25kg/stk)",
        blocksTotalText: "Antal blokke",
        mortelBagsTotalText: "Cement (kg) / 100 l kalkmørtel",
        bags: "sække",
        kalkmortel: "Kalkmørtel",
        byWeight: "Efter vægt",
        byVolume: "Efter rumfang",
        mortelType: "KC-Mørteltype",
        usedMortel: "Kalkmørtel, kalkindhold",
        waterContent: "Kalkmørtel, vandindhold",
        wetRoomWeight: "Kalkmørtel, våd rumvægt"
    }
}
