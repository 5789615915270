// Define shape object
let shapeObject = {
    shape1: {
        name: "Prisme"
    },
    shape2: {
        name: "Cylinder"
    },
    shape3: {
        name: "Trekant"
    }
};

// Define brick object
let brickObject = {
    brick1: {
        name: "Halvstensmur",
        value: 0.4
    },
    brick2: {
        name: "Helstensmur",
        value: 0.8
    },
    brick3: {
        name: "Halvandenstensmur",
        value: 1.2
    },
    brick4: {
        name: "Dobbeltstensmur",
        value: 1.6
    },
    brick5: {
        name: "Bloksten 10 cm",
        value: 0.1
    },
    brick6: {
        name: "Bloksten 20 cm",
        value: 0.16
    },
    brick7: {
        name: "Bloksten 25 cm",
        value: 0.2
    },
    brick8: {
        name: "Bloksten 30 cm",
        value: 0.25
    }
};


// Import texts

// Intro
import vejledning from '@/content/da/vejledning'
import disclaimer from '@/content/da/disclaimer'

// Privat
import pt0 from '@/content/da/privat/0'
import pt11 from '@/content/da/privat/1.1'
import pt21 from '@/content/da/privat/2.1'
import pt211 from '@/content/da/privat/2.1.1'
import pt31 from '@/content/da/privat/3.1'
import pt41 from '@/content/da/privat/4.1'

// Pro
import prot0 from '@/content/da/prof/0'
import prot11 from '@/content/da/prof/1.1'
import prot21 from '@/content/da/prof/2.1'
import prot211 from '@/content/da/prof/2.1.1'
import prot31 from '@/content/da/prof/3.1'
import prot41 from '@/content/da/prof/4.1'


// Export data
export default {
    data: {
        type: {
            data:{
                popup: {
                    vejledning: vejledning,
                    disclaimer: disclaimer
                }
            },
            options: {
                private: {
                    name: "Privat",
                    data: {
                        toggleText: "Hvad skal du lave?",
                        popup: pt0
                    },
                    options: {
                        cat1: {
                            name: "Betonkonstruktioner",
                            data: {
                                toggleText: "Vælg en kategori",
                                popup: pt11
                            },
                            options: {
                                type1: {
                                    name: "Udstøbning (fundamentsblokke)",
                                    // options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C20/25",
                                        recommendedCement: "F,B",
                                        cement: 1,
                                        sand: 3,
                                        stone: 4,
                                        stobemix: 6,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "kældervægge" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type2: {
                                    name: "Fundamenter (større byggerier)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C20/25",
                                        recommendedCement: "F,B",
                                        cement: 1,
                                        sand: 3,
                                        stone: 4,
                                        stobemix: 6,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "fundamenter" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type3: {
                                    name: "Fundamenter (parcelhuse)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C12/15",
                                        recommendedCement: "F,B",
                                        cement: 1,
                                        sand: 3.5,
                                        stone: 5,
                                        stobemix: 7,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "fundamenter" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type4: {
                                    name: "Fundamenter (delvist over terræn)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XC2, XC3, XC4, XF1, XA1",
                                        expectedStrengthClass: "C30/37",
                                        recommendedCement: "F,R",
                                        cement: 1,
                                        sand: 2,
                                        stone: 3,
                                        stobemix: 4,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "fundamenter" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type5: {
                                    name: "Vægge, søjler og bjælker (indendørs)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C25/30",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 2.5,
                                        stone: 3.5,
                                        stobemix: 5,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: '',
                                            description: ''
                                        }
                                    }
                                },
                                type6: {
                                    name: "Vægge, søjler og bjælker (udendørs)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XC2, XC3, XC4, XF1, XA1",
                                        expectedStrengthClass: "C30/37",
                                        recommendedCement: "F,R,W",
                                        cement: 1,
                                        sand: 2,
                                        stone: 3,
                                        stobemix: 4,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: '',
                                            description: ''
                                        }
                                    }
                                },
                                type7: {
                                    name: "Underbeton",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C25/30",
                                        recommendedCement: "F,B",
                                        cement: 1,
                                        sand: 2.5,
                                        stone: 3.5,
                                        stobemix: 5,
                                        maxWater: 170,
                                        gds: {
                                            youtube: 'OItHVOh41Io',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-udlaegning-af-beton/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type8: {
                                    name: "Trapper",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XD2, XD3, XS3, XF4, XA3",
                                        expectedStrengthClass: "C40/50",
                                        recommendedCement: "F,R,W",
                                        cement: 1,
                                        sand: 1.5,
                                        stone: 2.5,
                                        stobemix: 3.5,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "betontrapper" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type9: {
                                    name: "Konstruktioner påvirket af vandtryk",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XD2, XD3, XS3, XF4, XA3",
                                        expectedStrengthClass: "C40/50",
                                        recommendedCement: "F,R,W",
                                        cement: 1,
                                        sand: 1.5,
                                        stone: 2.5,
                                        stobemix: 3.5,
                                        maxWater: 170,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "belægninger og bassiner" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type10: {
                                    name: "Terazzobeton",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "-",
                                        expectedStrengthClass: "C40/50",
                                        recommendedCement: "W",
                                        cement: 1,
                                        sand: 0,
                                        stone: 3,
                                        stobemix: 0,
                                        maxWater: 210,
                                        gds: {
                                            youtube: '',
                                            link: '',
                                            description: ''
                                        }
                                    }
                                },
                                type11: {
                                    name: "Belægninger",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XD2, XD3, XS3, XF4, XA3",
                                        expectedStrengthClass: "C40/50",
                                        recommendedCement: "R,W",
                                        cement: 1,
                                        sand: 1.5,
                                        stone: 2.5,
                                        stobemix: 3.5,
                                        maxWater: 170,
                                        gds: {
                                            youtube: 'OItHVOh41Io',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-udlaegning-af-beton/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type12: {
                                    name: "Betongulve (kraftig slid)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XD2, XD3, XS3, XF4, XA3",
                                        expectedStrengthClass: "C40/50",
                                        recommendedCement: "F,R,W",
                                        cement: 1,
                                        sand: 1.5,
                                        stone: 2.5,
                                        stobemix: 3.5,
                                        maxWater: 170,
                                        gds: {
                                            youtube: 'OItHVOh41Io',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-udlaegning-af-beton/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type13: {
                                    name: "Betongulve (moderat slid)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XC2, XC3, XC4, XF1, XA1",
                                        expectedStrengthClass: "C30/37",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 2,
                                        stone: 3,
                                        stobemix: 4,
                                        maxWater: 170,
                                        gds: {
                                            youtube: 'OItHVOh41Io',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-udlaegning-af-beton/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type14: {
                                    name: "Betongulve (let slid)",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C25/30",
                                        recommendedCement: "R,B,W,F",
                                        cement: 1,
                                        sand: 2.5,
                                        stone: 3.5,
                                        stobemix: 5,
                                        maxWater: 170,
                                        gds: {
                                            youtube: 'OItHVOh41Io',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-udlaegning-af-beton/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type15: {
                                    name: "Klaplag, Renselag",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C8/10",
                                        recommendedCement: "B,F",
                                        cement: 1,
                                        sand: 4,
                                        stone: 6,
                                        stobemix: 8,
                                        maxWater: 170,
                                        gds: {
                                            youtube: 'OItHVOh41Io',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-udlaegning-af-beton/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                            }
                        },
                        cat2: {
                            name: "Opmuring",
                            data: {
                                popup: pt21,
                                toggleText: "Vælg en kategori"
                            },
                            options: {
                                type1: {
                                    name: "Ikke bærende murværk",
                                    options: brickObject,
                                    data: {
                                        exposureClasses: "MX1",
                                        recommendedCement: "B,R",
                                        kalkhydrat: 60,
                                        cement: 40,
                                        sand: 850,
                                        waterContent: 0.16,
                                        popup: pt211,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "murede vægge" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type2: {
                                    name: "Bærende murværk",
                                    options: brickObject,
                                    data: {
                                        exposureClasses: "MX2.1, MX2.2, MX3.1",
                                        recommendedCement: "R",
                                        kalkhydrat: 50,
                                        cement: 50,
                                        sand: 700,
                                        waterContent: 0.16,
                                        popup: pt211,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "murede vægge" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type3: {
                                    name: "Svært belastet murværk",
                                    options: brickObject,
                                    data: {
                                        exposureClasses: "MX3.2, MX4, MX5",
                                        recommendedCement: "R",
                                        kalkhydrat: 20,
                                        cement: 80,
                                        sand: 550,
                                        waterContent: 0.16,
                                        popup: pt211,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "murede vægge" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                            }
                        },
                        cat3: {
                            name: "Pudsning",
                            data: {
                                popup: pt31,
                                toggleText: "Vælg en kategori"
                            },
                            options: {
                                type1: {
                                    name: "Ikke bærende murværk",
                                    data: {
                                        exposureClasses: "MX1",
                                        recommendedCement: "B,R",
                                        kalkhydrat: 60,
                                        cement: 40,
                                        sand: 850,
                                        waterContent: 0.16,
                                        gds: {
                                            youtube: '',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Få råd og vejledning til din opgave under afsnittet "murede vægge" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type2: {
                                    name: "Bærende murværk",
                                    data: {
                                        exposureClasses: "MX2.1, MX2.2, MX3.1",
                                        recommendedCement: "R",
                                        kalkhydrat: 50,
                                        cement: 50,
                                        sand: 700,
                                        waterContent: 0.16,
                                        gds: {
                                            youtube: '_WrtBvPKT2A',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Hvis din opgave drejer sig om "Sokkelpuds", så se videoen ovenfor ellers kan du få råd og vejledning til din opgave under afsnittet "murede vægge" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                },
                                type3: {
                                    name: "Svært belastet murværk",
                                    data: {
                                        exposureClasses: "MX3.2, MX4, MX5",
                                        recommendedCement: "R",
                                        kalkhydrat: 20,
                                        cement: 80,
                                        sand: 550,
                                        waterContent: 0.16,
                                        gds: {
                                            youtube: '_WrtBvPKT2A',
                                            link: 'https://www.aalborgportland.dk/goer-det-lettere-at-goere-selv/',
                                            description: 'Hvis din opgave drejer sig om "Sokkelpuds", så se videoen ovenfor ellers kan du få råd og vejledning til din opgave under afsnittet "murede vægge" i vores Gør det lettere at gøre selv brochure på linket herunder:'
                                        }
                                    }
                                }
                            }
                        },
                        cat4: {
                            name: "Slidlag/afretning",
                            data: {
                                popup: pt41,
                                toggleText: "Vælg en kategori"
                            },
                            options: {
                                type1: {
                                    name: "Kælder og garage",
                                    data: {
                                        expectedStrengthClass: "20",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 4,
                                        oldSand: {
                                            weight: 5,
                                            density: 4
                                        },
                                        maxWater: 200,
                                        gds: {
                                            youtube: '9xsBmubrF6o',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-stoebning-af-slidlag/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type2: {
                                    name: "Anden belægning (træ og klinker)",
                                    data: {
                                        expectedStrengthClass: "25",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3.5,
                                        oldSand: {
                                            weight: 4,
                                            density: 3.5
                                        },
                                        maxWater: 200,
                                        gds: {
                                            youtube: '9xsBmubrF6o',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-stoebning-af-slidlag/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type3: {
                                    name: "Fodergange",
                                    data: {
                                        expectedStrengthClass: "25",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3.5,
                                        oldSand: {
                                            weight: 4,
                                            density: 3.5
                                        },
                                        maxWater: 200,
                                        gds: {
                                            youtube: '9xsBmubrF6o',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-stoebning-af-slidlag/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type4: {
                                    name: "Industrihaller",
                                    data: {
                                        expectedStrengthClass: "30",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3,
                                        oldSand: {
                                            weight: 3.5,
                                            density: 3
                                        },
                                        maxWater: 200,
                                        gds: {
                                            youtube: '9xsBmubrF6o',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-stoebning-af-slidlag/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type5: {
                                    name: "Lagerhaller",
                                    data: {
                                        expectedStrengthClass: "30",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3,
                                        oldSand: {
                                            weight: 3.5,
                                            density: 3
                                        },
                                        maxWater: 200,
                                        gds: {
                                            youtube: '9xsBmubrF6o',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-stoebning-af-slidlag/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                },
                                type6: {
                                    name: "Stier og båse",
                                    data: {
                                        expectedStrengthClass: "35",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 2.5,
                                        oldSand: {
                                            weight: 3,
                                            density: 2.5
                                        },
                                        maxWater: 200,
                                        gds: {
                                            youtube: '9xsBmubrF6o',
                                            link: 'https://www.aalborgportland.dk/videoguide-til-stoebning-af-slidlag/',
                                            description: 'Få råd og vejledning til din opgave i denne video.'
                                        }
                                    }
                                }
                            }
                        },
                    }
                },
                prof: {
                    name: "Prof.",
                    data: {
                        toggleText: "Hvad skal du lave?",
                        popup: prot0
                    },
                    options: {
                        cat1: {
                            name: "Betonkonstruktioner",
                            data: {
                                toggleText: "Vælg en kategori",
                                popup: prot11
                            },
                            options: {
                                typeTS: {
                                    name: "Tast selv",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "-",
                                        expectedStrengthClass: "-",
                                        recommendedCement: "-",
                                        cement: 1,
                                        sand: 1,
                                        stone: 1,
                                        stobemix: 1,
                                        maxWater: 170
                                    }
                                },
                                type0: {
                                    name: "Styrkeklasse – C8/10",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C8/10",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 4,
                                        stone: 6,
                                        stobemix: 8,
                                        maxWater: 170
                                    }
                                },
                                type1: {
                                    name: "Styrkeklasse – C12/15",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C12/15",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 3.5,
                                        stone: 5,
                                        stobemix: 7,
                                        maxWater: 170
                                    }
                                },
                                type2: {
                                    name: "Styrkeklasse – C20/25",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C20/25",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 3,
                                        stone: 4,
                                        stobemix: 6,
                                        maxWater: 170
                                    }
                                },
                                type3: {
                                    name: "Styrkeklasse – C25/30",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C25/30",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 2.5,
                                        stone: 3.5,
                                        stobemix: 5,
                                        maxWater: 170
                                    }
                                },
                                type4: {
                                    name: "Styrkeklasse – C30/37",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XC2, XC3, XC4, XF1, XA1",
                                        expectedStrengthClass: "C30/37",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 2,
                                        stone: 3,
                                        stobemix: 4,
                                        maxWater: 170
                                    }
                                },
                                type5: {
                                    name: "Styrkeklasse – C35/45",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XD1, XS1, XS2, XF2, XF3, XA2",
                                        expectedStrengthClass: "C35/45",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 1.5,
                                        stone: 3,
                                        stobemix: 3.75,
                                        maxWater: 170
                                    }
                                },
                                type6: {
                                    name: "Styrkeklasse – C40/50",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "-",
                                        exposureClasses: "XD2, XD3, XS3, XF4, XA3",
                                        expectedStrengthClass: "C40/50",
                                        recommendedCement: "F,R,W",
                                        cement: 1,
                                        sand: 1.5,
                                        stone: 2.5,
                                        stobemix: 3.5,
                                        maxWater: 170
                                    }
                                },
                                type7: {
                                    name: "Passiv miljøpåvirkning",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "P",
                                        exposureClasses: "X0, XC1",
                                        expectedStrengthClass: "C25/30",
                                        recommendedCement: "F,R,B,W",
                                        cement: 1,
                                        sand: 2.5,
                                        stone: 3.5,
                                        stobemix: 5,
                                        maxWater: 170
                                    }
                                },
                                type8: {
                                    name: "Moderat miljøpåvirkning",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "M",
                                        exposureClasses: "XC2, XC3, XC4, XF1, XA1",
                                        expectedStrengthClass: "C30/37",
                                        recommendedCement: "F,R,W",
                                        cement: 1,
                                        sand: 1.75,
                                        stone: 3,
                                        stobemix: 4,
                                        maxWater: 170
                                    }
                                },
                                type9: {
                                    name: "Aggressiv miljøpåvirkning",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "A",
                                        exposureClasses: "XD1, XS1, XS2, XF2, XF3, XA2",
                                        expectedStrengthClass: "C35/45",
                                        recommendedCement: "R,W",
                                        cement: 1,
                                        sand: 1.75,
                                        stone: 2,
                                        stobemix: 3.25,
                                        maxWater: 170
                                    }
                                },
                                type10: {
                                    name: "Ekstra aggressiv miljøpåvirkning",
                                    options: shapeObject,
                                    data: {
                                        environmentalImpact: "E",
                                        exposureClasses: "XD2, XD3, XS3, XF4, XA3",
                                        expectedStrengthClass: "C40/50",
                                        recommendedCement: "R,W",
                                        cement: 1,
                                        sand: 1.5,
                                        stone: 2,
                                        stobemix: 3,
                                        maxWater: 165
                                    }
                                },
                            }
                        },
                        cat2: {
                            name: "Opmuring",
                            data: {
                                popup: prot21,
                                toggleText: "Vælg en kategori"
                            },
                            options: {
                                type1: {
                                    name: "Ikke bærende murværk",
                                    options: brickObject,
                                    data: {
                                        exposureClasses: "MX1",
                                        recommendedCement: "B,R",
                                        kalkhydrat: 60,
                                        cement: 40,
                                        sand: 850,
                                        waterContent: 0.16,
                                        popup: prot211,
                                    }
                                },
                                type2: {
                                    name: "Bærende murværk",
                                    options: brickObject,
                                    data: {
                                        exposureClasses: "MX2.1, MX2.2, MX3.1",
                                        recommendedCement: "R",
                                        kalkhydrat: 50,
                                        cement: 50,
                                        sand: 700,
                                        waterContent: 0.16,
                                        popup: prot211,
                                    }
                                },
                                type3: {
                                    name: "Svært belastet murværk",
                                    options: brickObject,
                                    data: {
                                        exposureClasses: "MX3.2, MX4, MX5",
                                        recommendedCement: "R",
                                        kalkhydrat: 20,
                                        cement: 80,
                                        sand: 550,
                                        waterContent: 0.16,
                                        popup: prot211,
                                    }
                                },
                            }
                        },
                        cat3: {
                            name: "Pudsning",
                            data: {
                                popup: prot31,
                                toggleText: "Vælg en kategori"
                            },
                            options: {
                                type1: {
                                    name: "Ikke bærende murværk",
                                    data: {
                                        exposureClasses: "MX1",
                                        recommendedCement: "B,R",
                                        kalkhydrat: 60,
                                        cement: 40,
                                        sand: 850,
                                        waterContent: 0.16
                                    }
                                },
                                type2: {
                                    name: "Bærende murværk",
                                    data: {
                                        exposureClasses: "MX2.1, MX2.2, MX3.1",
                                        recommendedCement: "R",
                                        kalkhydrat: 50,
                                        cement: 50,
                                        sand: 700,
                                        waterContent: 0.16
                                    }
                                },
                                type3: {
                                    name: "Svært belastet murværk",
                                    data: {
                                        exposureClasses: "MX3.2, MX4, MX5",
                                        recommendedCement: "R",
                                        kalkhydrat: 20,
                                        cement: 80,
                                        sand: 550,
                                        waterContent: 0.16
                                    }
                                }
                            }
                        },
                        cat4: {
                            name: "Slidlag/afretning",
                            data: {
                                popup: prot41,
                                toggleText: "Vælg en kategori"
                            },
                            options: {
                                type1: {
                                    name: "Kælder og garage",
                                    data: {
                                        expectedStrengthClass: "20",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 4,
                                        oldSand: {
                                            weight: 5,
                                            density: 4
                                        },
                                        maxWater: 200
                                    }
                                },
                                type2: {
                                    name: "Anden belægning (træ og klinker)",
                                    data: {
                                        expectedStrengthClass: "25",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3.5,
                                        oldSand: {
                                            weight: 4,
                                            density: 3.5
                                        },
                                        maxWater: 200
                                    }
                                },
                                type3: {
                                    name: "Fodergange",
                                    data: {
                                        expectedStrengthClass: "25",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3.5,
                                        oldSand: {
                                            weight: 4,
                                            density: 3.5
                                        },
                                        maxWater: 200
                                    }
                                },
                                type4: {
                                    name: "Industrihaller",
                                    data: {
                                        expectedStrengthClass: "30",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3,
                                        oldSand: {
                                            weight: 3.5,
                                            density: 3
                                        },
                                        maxWater: 200
                                    }
                                },
                                type5: {
                                    name: "Lagerhaller",
                                    data: {
                                        expectedStrengthClass: "30",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 3,
                                        oldSand: {
                                            weight: 3.5,
                                            density: 3
                                        },
                                        maxWater: 200
                                    }
                                },
                                type6: {
                                    name: "Stier og båse",
                                    data: {
                                        expectedStrengthClass: "35",
                                        recommendedCement: "R,B",
                                        cement: 1,
                                        sand: 2.5,
                                        oldSand: {
                                            weight: 3,
                                            density: 2.5
                                        },
                                        maxWater: 200
                                    }
                                }
                            }
                        },
                    }
                }
            }
        }
    }
}
